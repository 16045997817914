/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import {
  AgentAssistApi,
  BadRequestErrorResponseContent,
  Configuration,
  InternalFailureErrorResponseContent,
  Middleware,
  NotAuthorizedErrorResponseContent,
  NotFoundErrorResponseContent,
  ResponseContext,
} from "@agent-assist/api-typescript-react-query-hooks";
import useSigV4Client from "@aws-northstar/ui/components/CognitoAuth/hooks/useSigv4Client";
import { useContext, useMemo } from "react";
import { RuntimeConfigContext } from "../providers/RuntimeContextProvider";

export interface ApiError {
  readonly status: number;
  readonly details:
    | BadRequestErrorResponseContent
    | InternalFailureErrorResponseContent
    | NotFoundErrorResponseContent
    | NotAuthorizedErrorResponseContent;
}

export const isApiError = (e: unknown): e is ApiError =>
  !!(e && typeof e === "object" && "status" in e && "details" in e);

export const errorTitle = (status: number): string => {
  switch (status) {
    case 400:
      return "Bad Request";
    case 403:
      return "Not Authorized";
    case 404:
      return "Not Found";
    case 500:
      return "Server Error";
    default:
      return "Error";
  }
};

/**
 * Middleware for handling API errors
 */
const errorHandlingMiddleware: Middleware = {
  post: async ({ response }: ResponseContext) => {
    if (response && response.status >= 400 && response.status < 600) {
      let details;
      try {
        details = await response.json();
      } catch (e) {
        // Unable to parse response body, so continue with default error handling
        return response;
      }
      throw <ApiError>{
        status: response.status,
        details,
      };
    }
    return response;
  },
};

export const useDefaultApiClient = () => {
  const client = useSigV4Client();
  const runtimeContext = useContext(RuntimeConfigContext);

  return useMemo(() => {
    return runtimeContext?.apiUrl
      ? new AgentAssistApi(
          new Configuration({
            basePath: runtimeContext.apiUrl.endsWith("/") ? runtimeContext.apiUrl.slice(0, -1) : runtimeContext.apiUrl,
            fetchApi: client,
            middleware: [errorHandlingMiddleware],
          }),
        )
      : undefined;
  }, [client, runtimeContext?.apiUrl]);
};
