/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import { Amplify } from "@aws-amplify/core";
import React, { createContext, useEffect, useState } from "react";

import { CardPrimary } from "../components/ui/card";

export interface RuntimeContext {
  readonly region: string;
  readonly userPoolId: string;
  readonly userPoolWebClientId: string;
  readonly identityPoolId: string;
  readonly notificationsApiUrl: string;
  readonly connectInstanceUrl: string;
  readonly connectInstanceIdToBaseUrlMapping?: { [key: string]: string };
  readonly [additionalProps: string]: any;
}

/**
 * Context for storing the runtimeContext.
 */
export const RuntimeConfigContext = createContext<RuntimeContext | undefined>(undefined);

const RuntimeContextProvider: React.FC<any> = ({ children }) => {
  const [runtimeContext, setRuntimeContext] = useState<RuntimeContext | undefined>();
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    fetch("/runtime-config.json")
      .then((response) => {
        return response.json();
      })
      .then((runtimeCtx) => {
        if (runtimeCtx.region && runtimeCtx.userPoolId && runtimeCtx.userPoolWebClientId && runtimeCtx.identityPoolId) {
          Amplify.configure({
            Auth: {
              region: runtimeCtx.region,
              userPoolId: runtimeCtx.userPoolId,
              userPoolWebClientId: runtimeCtx.userPoolWebClientId,
              identityPoolId: runtimeCtx.identityPoolId,
            },
            aws_appsync_graphqlEndpoint: runtimeCtx.notificationsApiUrl,
            aws_appsync_region: runtimeCtx.region,
            aws_appsync_authenticationType: "AWS_IAM",
          });
          setRuntimeContext(runtimeCtx as RuntimeContext);
        } else {
          setError("runtime-config.json should have region, userPoolId, userPoolWebClientId & identityPoolId.");
        }
      })
      .catch(() => {
        setError("No runtime-config.json detected");
      });
  }, [setRuntimeContext]);

  return error ? (
    <ErrorMessage>{error}</ErrorMessage>
  ) : (
    <RuntimeConfigContext.Provider value={runtimeContext}>{children}</RuntimeConfigContext.Provider>
  );
};

// ErrorMessage used to be https://github.com/aws/aws-northstar/tree/main/packages/ui/src/components/CognitoAuth/components/ErrorMessage
const ErrorMessage: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // this is just a quick fix to remove reliance on northstar ui:
  return <CardPrimary className="m-4">{children}</CardPrimary>;
};

export default RuntimeContextProvider;
