/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import { useEffect, useState } from "react";

export type StorageKey = "light-mode" | "max-app-width" | "dev-mode";

// consider using a typed approach for value:
const save = (key: StorageKey, value: any) => localStorage.setItem(key, JSON.stringify(value));

const load = (key: StorageKey) => {
  const value = localStorage.getItem(key);
  try {
    return value && JSON.parse(value);
  } catch (e) {
    console.warn(
      `⚠️ The ${key} value that is stored in localStorage is incorrect. Try to remove the value ${key} from localStorage and reload the page`,
    );
    return undefined;
  }
};

/**
 * Hook for a state value backed by local storage
 */
type ListenerCallback = (k: StorageKey, v: string) => void;
type Listener = {
  key: StorageKey;
  onChange: ListenerCallback;
};
const listeners: Listener[] = [];

export const useLocalStorage = <T>(
  key: StorageKey,
  defaultValue: T,
  onChange?: ListenerCallback,
): [T, (newValue: T) => void] => {
  const [value, setValue] = useState<T>(() => load(key) ?? defaultValue);

  useEffect(() => {
    if (!onChange) return;
    listeners.push({ key, onChange });
  }, [onChange]);

  function handleValueChange(newValue: T) {
    setValue(newValue);
    save(key, newValue);
    // dispatch to any (other) views listening in on the change event, but only for that key:
    listeners.forEach((f) => {
      if (f.key === key) {
        f.onChange(key, newValue as string);
      }
    });
  }
  return [value, handleValueChange];
};
