/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import { useCognitoAuthContext } from "@aws-northstar/ui";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license";
import camelCase from "lodash/camelCase";
import { type FC, useEffect } from "react";

import AppLayout from "./AppLayout";
import { getMuiTheme } from "./getMuiTheme";
import useLightMode from "../hooks/useLightMode";
import { setUser, TrusstGPTProvider, User, useTrusstGPTDispatch, useTrusstGPTState } from "../providers/AppProvider";
import { PromptProvider } from "../providers/PromptProvider";
import Routes from "../routes/Routes";

/**
 * Defines the App layout and contains logic for routing.
 */

LicenseInfo.setLicenseKey(
  "d4d0215f69c4f6e93830a6d77fbd2da4Tz05ODA3NCxFPTE3NTc4MjYyNTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixQVj1pbml0aWFsLEtWPTI=",
);

const App: FC = () => {
  useLightMode();
  const { getAuthenticatedUser } = useCognitoAuthContext();
  const dispatch = useTrusstGPTDispatch();
  const { isLightMode } = useTrusstGPTState();

  useEffect(() => {
    if (!getAuthenticatedUser) return;
    const authUser = getAuthenticatedUser();
    if (!authUser) throw new Error("no authUser");
    authUser.getSession(() =>
      authUser.getUserAttributes((_, attributes) => {
        if (!attributes) throw new Error("no attributes");
        const user = attributes?.reduce((acc, { Name, Value }) => {
          return { ...acc, [camelCase(Name)]: Value };
        }, {} as User);

        user.username = authUser.getUsername();

        user.signOut = async () => {
          authUser.signOut();
          window.location.href = "/";
        };

        setUser(dispatch, user);
      }),
    );
  }, [getAuthenticatedUser]);

  return (
    <ThemeProvider theme={getMuiTheme(isLightMode ? "light" : "dark")}>
      <PromptProvider>
        <AppLayout isLightMode={isLightMode}>
          <Routes />
        </AppLayout>
      </PromptProvider>
    </ThemeProvider>
  );
};

export default () => {
  // App logic with auth user needs to be wrapped in the TrusstGPTProvider here:
  return (
    <TrusstGPTProvider>
      <App />
    </TrusstGPTProvider>
  );
};
