/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import { PromptGroups, PromptQuestion, Properties } from "@agent-assist/api-typescript-runtime";

export const defaultPromptQuestionProperties: Properties = {
  weight: 0,
  autoFail: false,
  conditionalQA: false,
  positiveResult: true,
  isChild: false,
  isParent: false,
};

export const DEFAULT_PROMPT_QUESTIONS_ANALYTICS: PromptQuestion[] = [
  {
    id: "882d4655e02be53ddac7ee71a4a38ef88640d609",
    key: "intent",
    promptType: "extraction_single_label",
    question:
      "Don't use plural in your answer, e.g. This: 'Intent'.  Not this: 'Intents'. Extract a 1 to 3 word label that best describes the primary specific topic or intent of the call. Ensure the label is in plain text, comprehensible, representative of the topic, and free from any punctuation or unnecessary formatting.",
    properties: defaultPromptQuestionProperties,
  },
  {
    id: "a8f92991668d6074b1d8e7befebcf13f7c4558ad",
    key: "category",
    promptType: "extraction_single_label",
    question:
      "Don't use plural in your answer, e.g. Do this: 'Technical Issue'.  Not this: 'Technical Issues'. The Category of the issue discused. This is the first part, Category, of Category, Sub-Category, and Sub-Sub-Category. These labels will be presented in a drill-down user interface, so they must be meaningful and distinct.",
    properties: defaultPromptQuestionProperties,
  },
  {
    id: "80bfca6832e811c1bcda16a65df8212428f76ac3",
    key: "sub-category",
    promptType: "extraction_single_label",
    question:
      "Don't use plural in your answer, e.g. Do this: 'Technical Issue'.  Not this: 'Technical Issues'. The sub-category of the issue. This is the second part, Category, of Category, Sub-Category, and Sub-Sub-Category. These labels will be presented in a drill-down user interface, so they must be meaningful and distinct.",
    properties: defaultPromptQuestionProperties,
  },
  {
    id: "8b537e6121fead294eb9556e8dc365a0769d5aa0",
    key: "sub-sub-category",
    promptType: "extraction_single_label",
    question:
      "Don't use plurals in your answer, e.g. Do this: 'Technical Issue'.  Not this: 'Technical Issues'. The sub-sub-category of the issue. This is the third part, Category, of Category, Sub-Category, and Sub-Sub-Category. These labels will be presented in a drill-down user interface, so they must be meaningful and distinct. You must answer, do not answer with 'unknown'.",
    properties: defaultPromptQuestionProperties,
  },
  {
    id: "9cfa9bcbc3a02a19a050e99fe2cd34983c8c5eb0",
    key: "resolution",
    promptType: "extraction_binary",
    question: "You are the customer in this conversation. Did the agent achieve a satisfactory resolution for you?",
    properties: {
      ...defaultPromptQuestionProperties,
      promptVisualisations: [
        {
          enabled: true,
          visualisationType: "Aggregate",
          tooltip:
            "This is judged by TrusstGPT as if TrusstGPT was the customer being asked if they were satisfied with the resolution.",
        },
      ],
    },
  },
  {
    id: "f24378a7ebded1cd6bf8ba92fa463a21a32e2bc7",
    key: "agent sentiment",
    promptType: "extraction_rating",
    question:
      "Assess the agent's responses for indicators of stress and cognitive load, considering factors such as response time, clarity of communication, ability to multitask, and signs of mental fatigue throughout the dialogue.\n" +
      "[INST]\n" +
      "Rate the agent's stress and cognitive load on a scale from -5 to 5, where:\n" +
      "-5 indicates extreme cognitive overload, with the agent struggling significantly to manage the interaction, showing clear signs of mental exhaustion.\n" +
      "-4 indicates high stress levels, with the agent showing notable difficulty in processing information and frequent pauses or hesitations.\n" +
      "-3 indicates moderate stress, where the agent is visibly strained and takes longer to respond.\n" +
      "-2 indicates mild stress, with the agent showing some signs of pressure, such as slight delays in responses.\n" +
      "-1 indicates slight stress, where the agent is managing but shows subtle signs of strain.\n" +
      "0 indicates a balanced state, where the agent is handling cognitive demands without visible stress or exceptional ease.\n" +
      "1 indicates slight ease, where the agent is comfortably managing the interaction with minimal signs of cognitive strain.\n" +
      "2 indicates good cognitive management, with the agent smoothly handling multiple aspects of the interaction.\n" +
      "3 indicates proficient cognitive performance, where the agent easily juggles complex information and tasks.\n" +
      "4 indicates high cognitive efficiency, with the agent demonstrating exceptional information processing abilities.\n" +
      "5 indicates optimal cognitive performance, where the agent manages the interaction with remarkable ease under any circumstances.\n" +
      "Consider response times, clarity of explanations, ability to recall information, adaptability to unexpected situations, and maintenance of professionalism under pressure.\n" +
      "Output: Provide a single numerical score from -5 to 5 representing the overall level of stress and cognitive load experienced by the agent across the entire conversation.\n" +
      "[/INST].\n",
    properties: {
      ...defaultPromptQuestionProperties,
      promptVisualisations: [
        {
          enabled: true,
          visualisationType: "Aggregate",
          tooltip:
            "TrusstGPT embodies the agent's perspective in the conversation, emphasizing the evaluation of their stress level and emotional state throughout the interaction. This is measured on a scale of -5 to +5, with negative 5 being the most negative sentiment, positive 5 being the most positive sentiment, and 0 being neutral.",
        },
      ],
    },
  },
  {
    id: "993df5aff08ce74f9da6cc82ab932f118f8b85df",
    key: "customer sentiment",
    promptType: "extraction_rating",
    question:
      "Your task is to assume the role of the customer in this conversation and evaluate your own sentiment throughout the interaction, focusing solely on your contributions as the customer.\n" +
      "Assess the emotional tone of your own language, considering factors such as your word choice, tone indicators, and reactions to the agent.\n" +
      "[INST]\n" +
      "Rate your sentiment as the customer on a scale from -5 to 5, where:\n" +
      "-5 indicates you are extremely dissatisfied, frequently expressing frustration or anger in your responses.\n" +
      "-4 indicates you show clear signs of dissatisfaction, like noticeable annoyance or impatience.\n" +
      "-3 indicates you are somewhat dissatisfied, voicing minor complaints or disappointment.\n" +
      "-2 indicates a slightly negative sentiment, where your tone shows mild displeasure or discomfort with the service.\n" +
      "-1 indicates you are marginally dissatisfied, occasionally noting minor issues but not overtly critical.\n" +
      "0 indicates a neutral sentiment, where your tone is neither positive nor negative, focusing on factual or transactional dialogue.\n" +
      "1 indicates a somewhat positive sentiment, where you show subtle approval of certain aspects of the service.\n" +
      "2 indicates you are somewhat satisfied, expressing mild contentment or comfort with the interaction.\n" +
      "3 indicates you are noticeably satisfied, showing clear signs of approval regarding the service.\n" +
      "4 indicates you are very satisfied, expressing enthusiasm and positive feedback.\n" +
      "5 indicates you are extremely satisfied, exhibiting high enthusiasm and voicing strong gratitude.\n" +
      "Consider subtle cues in your own responses like politeness, enthusiasm, frustration, or reluctance.\n" +
      "Pay attention to your responsiveness and any empathy you showed towards the agent.\n" +
      "Note any shifts in your sentiment throughout the conversation and reflect this in your overall rating.\n" +
      "Output: Provide a single numerical score from -5 to 5 representing your overall sentiment as the customer in this conversation.\n[/INST].\n",
    properties: {
      ...defaultPromptQuestionProperties,
      promptVisualisations: [
        {
          enabled: true,
          visualisationType: "Aggregate",
          tooltip:
            "TrusstGPT embodies the customer's perspective in the conversation. The sentiment is measured on a scale of -5 to +5, with negative 5 being the most negative sentiment, positive 5 being the most positive sentiment, and 0 being neutral.",
        },
      ],
    },
  },
  {
    id: "dc5f7f5edc66ae4b6692182ab2da6eeb12e38282",
    key: "product",
    promptType: "extraction_single_label",
    question: "What is the primary specific product or service that the customer asked about?",
    properties: defaultPromptQuestionProperties,
  },
  {
    id: "612c842cf86723dcf53f9b2681e0c410f6bd334d",
    key: "repeat call",
    promptType: "extraction_binary",
    question:
      "Were there any direct mentions or hints that suggest the customer previously contacted the organisation about the same issue.\n" +
      "This could be explicit (e.g., 'I called last week about this and...') or implicit (e.g., 'We have discussed this before...'). \n" +
      "Identify keywords or phrases such as 'again', 'still', 'another call', 'last time', which might indicate previous interactions about the same problem. \n" +
      "Analyse the context in which these statements are made to confirm whether they relate to repeat contacts about the same problem.",
    properties: {
      ...defaultPromptQuestionProperties,
      promptVisualisations: [
        {
          enabled: true,
          visualisationType: "Aggregate",
          tooltip:
            "This is judged by TrusstGPT's model based on whether a customer mentions anything that infers that they have had to call more than once about the same problem during the current conversation.",
        },
      ],
    },
  },
];

export const DEFAULT_PROMPT_GROUPS: PromptGroups = {
  synopsis: {
    promptGroupType: "synopsis",
    totalWeight: 100,
    questions: [
      {
        key: "summary",
        promptType: "synthesis",
        question: "Summarise the conversation in a succinct paragraph.",
        properties: defaultPromptQuestionProperties,
        id: "dde5608026b6052497cc9c8797e209a2b6b4a936",
      },
      {
        key: "customer problem",
        promptType: "synthesis",
        question: "Briefly describe the reason for the customer contacting in a succinct paragraph.",
        properties: defaultPromptQuestionProperties,
        id: "f896771cfe423e676ab0ff3e7345a08a23d00c34",
      },
      {
        key: "agent action",
        promptType: "synthesis",
        question:
          "Briefly describe the action the agent took to resolve the customer's problem in a succinct paragraph.",
        properties: defaultPromptQuestionProperties,
        id: "ccc110b0956baa9a04c5fb7593baf70a3f5e089a",
      },
      {
        key: "customer effort",
        promptType: "extraction_rating",
        question:
          "You are the customer in this conversation. On a scale of 1 to 10, with 10 being the highest level of effort and 1 being the lowest, rate the level of effort you experienced in resolving your issue during this conversation.",
        properties: defaultPromptQuestionProperties,
        id: "c48be67508d91ecec4881e19649b6a51f5ad0b5d",
      },
      {
        key: "churn",
        promptType: "extraction_rating",
        question:
          "You are the customer in this conversation. On a scale of 1 to 10, with 10 being the most likely and 1 being the least likely, rate the probability that you will switch to a different provider based on this conversation.",
        properties: defaultPromptQuestionProperties,
        id: "f12dda2dbfaaf7bea23e4ea875d583f4a183f566",
      },
    ],
  },
  softSkills: {
    promptGroupType: "qa",
    totalWeight: 91,
    questions: [
      {
        key: "Introduction",
        question:
          "Did the agent introduce themselves articulately and maintain a positive tone while offering assistance. Be concise",
        promptType: "extraction_binary",
        properties: {
          ...defaultPromptQuestionProperties,
          weight: 7,
        },
        id: "5d169da200ae158b6766210e90148ad71c8c8b97",
      },
      {
        key: "Trust and Rapport",
        question:
          "Was the agent believable, easily understood and did they connect with the customer. This is judged by whether the agent performs the following; uses simple language, avoids acronyms, use the members name during the conversation, is confident in what they are saying, and is helpful. Be concise",
        promptType: "extraction_binary",
        properties: {
          ...defaultPromptQuestionProperties,
          weight: 7,
        },
        id: "ba0d7bdc743d1203b12ef5ba46c781fc86c1e3a5",
      },
      {
        key: "empathy",
        question:
          "Did the agent demonstrate empathy and understanding towards the customer, and use appropriate de-escalation techniques when necessary? Be concise",
        promptType: "extraction_binary",
        properties: {
          ...defaultPromptQuestionProperties,
          weight: 7,
        },
        id: "0b8d09646994a3f997e3d1b3e085945717c3179f",
      },
      {
        key: "Effective Questioning",
        question:
          "Did the agent use effective questions (open, leading, closed) to gain clarity of the customer's query and to ensure the solution provided meets the customer's requirements? Be concise",
        promptType: "extraction_binary",
        properties: {
          ...defaultPromptQuestionProperties,
          weight: 7,
        },
        id: "164f53d0a680755df417d5b027baf8e1a5e9e924",
      },
      {
        key: "Active listening",
        question:
          "Did the agent use verbal prompts to demonstrate they are listening and allow the customer to speak uninterrupted, waiting for the customer's full response? Be concise",
        promptType: "extraction_binary",
        properties: {
          ...defaultPromptQuestionProperties,
          weight: 7,
        },
        id: "1f3aa4615a5ba8bf5f671dc84d8adaf57e53a439",
      },
      {
        key: "Clarify query",
        question:
          "Did the agent use rephrasing and chunking techniques to ensure the member's query is clearly understood in the interaction, e.g. Rephrasing the customer's query to gain clarity & consent to proceed, and chunking the query when dealing with multiple topics? Be concise",
        promptType: "extraction_binary",
        properties: {
          ...defaultPromptQuestionProperties,
          weight: 7,
        },
        id: "1682423b94d058c0a60fc6bd486ebcd13b62f1e6",
      },
      {
        key: "Conversation flow",
        question:
          "Did the agent handle the customer's query with confidence, set the scene of what actions will take place, both during and after (if appropriate) the call to ensure the matter is resolved. Be concise",
        promptType: "extraction_binary",
        properties: {
          ...defaultPromptQuestionProperties,
          weight: 7,
        },
        id: "51072f326f77335a3d7a71a086face14815c0ad2",
      },
      {
        key: "Personal Advice",
        question:
          "If there is an agent present in the transcript, did the agent comply with guidelines by not giving any personal advice, including financial advice? Congratulatory messages or general acknowledgments are not considered personal advice. Be concise",
        promptType: "extraction_binary",
        properties: {
          ...defaultPromptQuestionProperties,
          weight: 7,
        },
        id: "9b95ec8fb7599ebbc7a3df82bb9456a8c501f4b9",
      },
      {
        key: "Digital Education",
        question:
          "Did the agent actively promote and educate the customer on the use of digital interfaces or other appropriate engagement channels for future engagement, such as a mobile application, website, chat messaging or other appropriate channels? Be concise",
        promptType: "extraction_binary",
        properties: {
          ...defaultPromptQuestionProperties,
          weight: 7,
        },
        id: "7edbd12c7599e5b1e4d28ac61fcaca42ed9bece7",
      },
      {
        key: "System Usage",
        question:
          "Did the agent reference details about the customer's profile, such as specifics about the customers account, or alternative product or service offerings? Be concise",
        promptType: "extraction_binary",
        properties: {
          ...defaultPromptQuestionProperties,
          weight: 7,
        },
        id: "30eb22b78d6370596df7a8e71891397bbd7d2003",
      },
      {
        key: "Engaging team",
        question:
          "If the agent needed to engage with another member of their team, did they perform a warm transfer, and explain the purpose of needing to engage with other members of their team to resolve the customer's inquiry. Be concise",
        promptType: "extraction_binary",
        properties: {
          ...defaultPromptQuestionProperties,
          weight: 7,
        },
        id: "7c301af5bf206d2f4d92fa8bf9f144d49a842437",
      },
      {
        key: "Conclusion of conversation",
        question:
          "Did the agent summarise the initial query/request and outline the steps taken to resolve this. Be concise",
        promptType: "extraction_binary",
        properties: {
          ...defaultPromptQuestionProperties,
          weight: 7,
        },
        id: "748623b40bb168de471332a7dd5ce5642aa04099",
      },
      {
        key: "Moments that matter",
        question:
          "If appropriate, did the agent celebrate moments that matter to the customer such as the customer reaching retirement, congratulating the customer about a recent birthday etc. Be concise ",
        promptType: "extraction_binary",
        properties: {
          ...defaultPromptQuestionProperties,
          weight: 7,
        },
        id: "61bded87544a91450eef4f1848e4f833c4ddaa7a",
      },
    ],
  },
  compliance: {
    promptGroupType: "qa",
    totalWeight: 100,
    questions: [
      {
        key: "Identity Verification",
        question:
          "Is the customer authenticated by the agent by requesting the following information during the conversation; customer name, date of birth, and 6 digit one time PIN sent to the customer's mobile number on file? Be concise",
        promptType: "extraction_binary",
        properties: {
          ...defaultPromptQuestionProperties,
          weight: 100,
        },
        id: "f18fabb548bfd6a20906adb7993b49285f66e155",
      },
    ],
  },
};
