import { CircularProgress } from "@mui/material";

const spinnerSizes = {
  xs: 16,
  sm: 20,
  md: 28,
  lg: 36,
};

type LoadingSpinnerProps = {
  size?: "xs" | "sm" | "md" | "lg";
};

export const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ size = "md" }) => {
  const spinnerSize = spinnerSizes[size];
  return <CircularProgress size={spinnerSize} />;
};
