/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import { ChevronRight } from "lucide-react";
import { Fragment, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";

import { DEFAULT_PROMPT_ID } from "../../lib/constants";
import { shortenUuid, wordToTitleCase } from "../../lib/formatString";
import { cn } from "../../lib/utils";

export const titleCase = (sentence: string, delimiter = " ") => {
  const shortUuid = shortenUuid(sentence);
  // Don't title case it if it has a uuid. This is to preserve IDs in the URL.
  return (
    (
      shortUuid === sentence // if it matches the original, it doesn't contain a uuid.
        ? sentence
            .split(delimiter)
            .map((w) => wordToTitleCase(w))
            .join(" ")
        : shortUuid
    )
      // replace specific slugs with more human readable titles:
      .replace(DEFAULT_PROMPT_ID, "Default Prompt")
      .replace("Prompts", "Prompt Revisions")
  );
};

const Slash = () => <ChevronRight className="mx-1 sm:mx-2" size={12} />;

const highlight = (isHighlighted: boolean) =>
  cn(isHighlighted ? "font-bold text-muted-foreground" : "text-muted-foreground/80 hover:underline");

const Breadcrumbs = () => {
  const location = useLocation();

  const paths = useMemo(() => location.pathname.split("/").filter(Boolean), [location]);
  const isHome = paths.length === 0;

  return (
    <div className="flex text-sm py-1 w-full whitespace-nowrap items-center">
      <Link to="/" className={highlight(isHome)}>
        Home
      </Link>
      {!isHome && <Slash />}
      {paths.map((path, index) => {
        const isLast = index === paths.length - 1;
        return (
          <Fragment key={index}>
            <Link to={`${paths.slice(0, index + 1).join("/")}`} className={highlight(isLast)}>
              {titleCase(path, "-")}
            </Link>
            {!isLast && <Slash />}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
