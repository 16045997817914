/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { titleCase } from "../app/NavBar/Breadcrumbs";

export const useDocumentTitle = () => {
  const location = useLocation();
  const [rootPath] = useMemo(() => location.pathname.split("/").filter(Boolean), [location]);
  useEffect(() => {
    document.title = `${titleCase(rootPath)} - TrusstAI`;
  }, [rootPath]);
};
