/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes as ReactRoutes } from "react-router-dom";
import { Redirect as RedirectWithParams } from "react-router6-redirect";

import { NoMatch } from "./NoMatch";
import { ScreenLoader } from "../components/ScreenLoader";
import { useDocumentTitle } from "../hooks/useDocumentTitle";

const CreateAgent = lazy(() => import("../pages/trussted-agents/CreateAgent"));
const EditAgentTools = lazy(() => import("../pages/trussted-agents/EditAgentTools"));
const EditAgent = lazy(() => import("../pages/trussted-agents/EditAgent"));
const Analytics = lazy(() => import("../pages/analytics/analytics"));
const Contacts = lazy(() => import("../pages/contact/contacts"));
const ViewContact = lazy(() => import("../pages/contact/view-contact"));
const ContactImports = lazy(() => import("../pages/contact-import/contact-imports"));
const ViewContactImport = lazy(() => import("../pages/contact-import/ViewContactImport"));
const DataDialog = lazy(() => import("../pages/data-dialog/data-dialog"));
const QAAnalytics = lazy(() => import("../pages/QA/analytics"));
const CreateQAPrompts = lazy(() => import("../pages/QA/prompts/createQAPrompts"));
const QAPrompts = lazy(() => import("../pages/QA/prompts/ListQAPrompts"));
const QAPublishedPrompt = lazy(() => import("../pages/QA/prompts/PublishedQAPrompt"));
const ViewQAPrompt = lazy(() => import("../pages/QA/prompts/ViewQAPrompt"));
const Settings = lazy(() => import("../pages/settings/Settings"));
const ViewAgentTools = lazy(() => import("../pages/trussted-agents/ViewAgentTools"));
const TrusstedAgents = lazy(() => import("../pages/trussted-agents/TrusstedAgents"));
const DevPlayground = lazy(() => import("../pages/dev-playground/DevPlayground"));
const Profile = lazy(() => import("../pages/profile/profile"));

const LazyLoad = ({ Component }: { Component: React.LazyExoticComponent<any> }) => {
  useDocumentTitle();

  return (
    <Suspense fallback={<ScreenLoader />}>
      <Component />
    </Suspense>
  );
};
/**
 * Defines the Routes with lazy loading
 *
 * In production builds we use this (RoutesLazy), which utilises lazy loading to harness the benefits of code splitting and lazy loading of compiled JS chunks
 *
 * DO NOT edit Routes.tsx which gets overwritten at compile time!
 */
const Routes: FC = () => {
  // if adding any new routes, also add to `RoutesEager.tsx`!
  return (
    <ReactRoutes>
      {/* Contacts paths: */}
      <Route path="/contacts/:contactId" element={<LazyLoad Component={ViewContact} />} />
      <Route path="/contacts" element={<LazyLoad Component={Contacts} />} />

      {/* Imports paths: */}
      <Route path="/imports/:contactImportId/contacts/:contactId" element={<LazyLoad Component={ViewContact} />} />
      <Route
        path="/imports/:contactImportId/contacts" // no logical path here:
        element={<RedirectWithParams to="/imports/:contactImportId" />}
      />
      <Route path="/imports/:contactImportId" element={<LazyLoad Component={ViewContactImport} />} />
      <Route path="/imports" element={<LazyLoad Component={ContactImports} />} />

      {/* Trussted Agent paths: */}
      <Route path="/trussted-agents" element={<LazyLoad Component={TrusstedAgents} />} />
      <Route path="/trussted-agents/create" element={<LazyLoad Component={CreateAgent} />} />
      <Route path="/trussted-agents/edit/:agentId" element={<LazyLoad Component={EditAgent} />} />
      <Route path="/trussted-agents/edit" element={<RedirectWithParams to="/trussted-agents" />} />
      <Route path="/trussted-agents/edit-tools/:agentId" element={<LazyLoad Component={EditAgentTools} />} />
      <Route path="/trussted-agents/edit-tools" element={<RedirectWithParams to="/trussted-agents" />} />
      <Route path="/trussted-agents/:agentId" element={<LazyLoad Component={ViewAgentTools} />} />

      {/* Analytics paths: */}
      <Route path="/analytics/:contactImportId/:promptRevisionId" element={<LazyLoad Component={Analytics} />} />
      <Route
        path="/analytics/:contactImportId" // promptRevisionId is optional and will adopt a default if missing
        element={<LazyLoad Component={Analytics} />}
      />
      <Route path="/analytics" element={<LazyLoad Component={Analytics} />} />

      {/* QA paths: */}
      <Route path="/prompts/create" element={<LazyLoad Component={CreateQAPrompts} />} />
      <Route path="/prompts/edit/:promptRevisionId" element={<LazyLoad Component={CreateQAPrompts} />} />
      <Route path="/prompts/edit" element={<RedirectWithParams to="/prompts" />} />
      <Route path="/prompts" element={<LazyLoad Component={QAPrompts} />} />
      <Route path="/prompts/:promptRevisionId" element={<LazyLoad Component={ViewQAPrompt} />} />
      <Route path="/prompts/live" element={<LazyLoad Component={QAPublishedPrompt} />} />
      <Route path="/evaluation/:contactImportId" element={<LazyLoad Component={QAAnalytics} />} />
      <Route path="/evaluation" element={<LazyLoad Component={QAAnalytics} />} />

      {/* Deprecated paths: */}
      {/* Following paths can be removed 03/25: (see also RoutesEager) */}
      <Route path="/QA-prompts/:alpha/:beta" element={<RedirectWithParams to="/prompts/:alpha/:beta" />} />
      <Route path="/QA-prompts/:alpha" element={<RedirectWithParams to="/prompts/:alpha" />} />
      <Route path="/QA-prompts/" element={<RedirectWithParams to="/prompts" />} />
      <Route path="/QA-analytics/:alpha" element={<RedirectWithParams to="/evaluation/:alpha" />} />
      <Route path="/QA-analytics" element={<RedirectWithParams to="/evaluation" />} />

      {/* All other paths: */}
      <Route path="/data-dialog" element={<LazyLoad Component={DataDialog} />} />
      <Route path="/profile" element={<LazyLoad Component={Profile} />} />
      <Route path="/settings" element={<LazyLoad Component={Settings} />} />
      <Route path="/dev-playground/:viewId?" element={<LazyLoad Component={DevPlayground} />} />
      <Route path="/" element={<Navigate to="/analytics" />} />

      {/* 404 path: */}
      <Route path="*" element={<NoMatch />} />
    </ReactRoutes>
  );
};

export default Routes;
