/************************************************************************************************
 * Copyright TRUSST AI PTY LTD. All Rights Reserved.                                            *
 *                                                                                              *
 * Licensed under the TRUSST SOFTWARE LICENSE (the "License"). You may not use this file except *
 * in compliance with the "LICENSE" file accompanying this file. This file is distributed       *
 * on an "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, express or implied.       *
 *                                                                                              *
 * See the "License" file for the specific language governing permissions and limitations       *
 * under the License and limitations under the License.                                         *
 ***********************************************************************************************/

import { type PaletteMode } from "@mui/material";
import { createTheme } from "@mui/material/styles";

export const getMuiTheme = (mode: PaletteMode = "light") => (mode === "light" ? darkTheme : lightTheme);

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      light: "#f0f",
      main: "hsl(var(--primary))",
      dark: "hsl(var(--card))",
    },
    // error?: PaletteColorOptions;
    // warning?: PaletteColorOptions;
    // info?: PaletteColorOptions;
    // success?: PaletteColorOptions;
    // tonalOffset?: PaletteTonalOffset;
    // contrastThreshold?: number;
    text: {
      primary: "hsl(var(--foreground))",
      secondary: "hsl(var(--muted-foreground))",
      disabled: "#0ff",
    },
    divider: "hsl(var(--background))",
    action: {
      active: "hsl(var(--primary))",
      hover: "hsl(var(--background))",
      hoverOpacity: 0.04,
      selected: "hsl(var(--card))",
      selectedOpacity: 0.04,
      disabled: "#fff6", // TODO?
      disabledOpacity: 0.01,
      disabledBackground: "#00f",
      focus: "hsl(var(--primary))",
      focusOpacity: 0.5,
      activatedOpacity: 1,
    },
    background: {
      default: "hsl(var(--muted))",
      paper: "hsl(var(--muted))",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        containedSecondary: {
          "&:hover": { backgroundColor: "hsl(var(--primary))" },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          // needed for Datepicker shortcuts:
          backgroundColor: "hsl(var(--accent))",
          color: "hsl(var(--accent-foreground))",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: "hsl(var(--card))",
          backgroundImage: "none",
          borderRadius: "4px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          margin: "24px 0 0",
          padding: 0,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "32px",
          "&:first-of-type": {
            padding: "32px !important", // needed for Datepicker
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: { padding: "0 0 32px" },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: "4px 0", // needed for Datepicker shortcuts
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          top: "-4px", // initial label position
          "&.MuiInputLabel-shrink": {
            top: "-0px", // position when label shrinks (moves to the top)
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "hsl(var(--background))",
          borderRadius: "calc(var(--radius) - 2px)",
          height: "3em",
          padding: "0",
          "& fieldset": { borderColor: "hsl(var(--primary))" },
        },
      },
    },
    /*
    // note: `Mui*` specific:
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.MuiPickersToolbarButton-root": {
            color: "red", // Time button color
          },
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: { backgroundColor: "hsl(var(--muted))", color: "hsl(var(--muted-foreground))" },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: { color: "hsl(var(--foreground))" },
      },
    },

    MuiFormControl: {
      styleOverrides: {
        root: { padding: "1rem" },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "hsl(var(--muted))",
          color: "hsl(var(--muted-foreground))",
          "& .tss-1cdcmys-MUIDataTable-responsiveBase": {
            height: "80% !important",
          },
        },
      },
    },

    MuiSelected: {},

    MuiPickersLayout: {
      styleOverrides: {
        root: {
          backgroundColor: "hsl(var(--muted))",
        },
      },
    },

    MuiPickersToolbarText: {
      styleOverrides: {
        root: {
          color: "white",
          // backgroundColor: "red",
          "&.Mui-selected": {
            color: "green",
          },
        },
        selected: {
          backgroundColor: "green", // Selected day background color
          color: "white", // Selected day text color
        },
      },
    },

    MuiSvgIcon: {
      styleOverrides: {
        root: { color: "hsl(var(--primary))", fill: "hsl(var(--primary))" },
      },
    },

    MuiTable: {
      styleOverrides: {
        root: { backgroundColor: "hsl(var(--background))" },
      },
    },

    */
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid hsl(var(--background))",
          /*
          backgroundColor: "hsl(var(--background))",
          "&.childRow": {
            backgroundColor: "hsl(var(--accent)) !important",
          },
          */
        },
        /*
        head: { backgroundColor: "hsl(var(--background))", color: "hsl(var(--foreground))" },
        body: {
          backgroundColor: "hsl(var(--muted))",
          "&.childRow": {
            backgroundColor: "hsl(var(--accent)) !important",
          },
          color: "hsl(var(--muted-foreground))",
          textAlign: "center",
          "&.questionAlign": {
            textAlign: "left",
          },
        },
        footer: { backgroundColor: "hsl(var(--background))", color: "hsl(var(--foreground))" },
        sizeMedium: { fontSize: "14px" },
        */
      },
    },
    /*

    MuiTablePagination: {
      styleOverrides: {
        root: { backgroundColor: "hsl(var(--background))", color: "hsl(var(--foreground))" },
      },
    },

    MuiTypography: {
      styleOverrides: {
        root: { backgroundColor: "hsl(var(--muted))", color: "hsl(var(--muted-foreground))" },
      },
    },

    // note: `MUIDataTable*` specific:
    MUIDataTable: {
      styleOverrides: {
        /*
          MUIDataTable adds a div at the bottom of its tables to display announcements like: "stuff now sorted by category descending"
          This doesn't (never actually did?) display in our use case.
          Unfortunately it has some questionable default CSS (like `margin: -1px`) which caused rendering issues in our app.
          Obviously this change means it can never display!
        //
        liveAnnounce: { display: "none" },
        root: { height: "100%" },
      },
    },

    MUIDataTableFilter: {
      styleOverrides: {
        root: { backgroundColor: "hsl(var(--muted))", color: "hsl(var(--muted-foreground))" },
        title: { color: "hsl(var(--foreground))" },
      },
    },

    MUIDataTableHeadCell: {
      styleOverrides: {
        data: { textTransform: "capitalize", fontWeight: "bold" },
        root: { justifyContent: "center", backgroundColor: "hsl(var(--background))" },
        sortActive: { color: "hsl(var(--foreground))" },
      },
    },

    MUIDataTableToolbar: {
      styleOverrides: {
        root: { backgroundColor: "hsl(var(--muted))", color: "hsl(var(--muted-foreground))" },
      },
    },
    // other attributes that almost, but did not work for recent use cases... they may for yours:
    MuiTableFooter: {},
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.expanded-header": {
            position: "sticky",
            top: "0",
          },
        },
      },
    },

    MUIDataTableFooter: {},
    MUIDataTableViewCol: {},
    */
  },

  typography: {
    fontSize: 12,
  },
});

const lightTheme = darkTheme; // there is currently no variance since we are using css vars, but obviously there will be a need.
